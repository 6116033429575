import React, { useState, useRef, useEffect } from 'react'
import { HeaderProps } from '../header/Header'
import Head from 'next/head'
import logo_white from '../../static/imgs/logo_frosted.png'
import logo_blue from '../../static/imgs/logo_frosted.png'
import { navHeaderProps } from '../../services/api/constants'
import {
  LAYOUT_TEMPLATE,
  Link,
  MenuAPIProps,
  MenuProps,
  SiteSettingProps
} from '../../services/api/types'
import ReactGA from 'react-ga'
import { MenuContext } from '../../services/context/MenuContext'
import { OwnerEnquireFormProps } from '../forms/OwnerEnquireForm'
import { getJoinUrlParams, isNullOrEmpty } from '../../services/utils'
import * as S from './Navigation.styles'
import Modal from '../modal/Modal'
import { GymSearchCard } from '../gym-search-card/GymSearchCard'
import { IconEnums, SVGIcon } from '../base/icon/svgIcon'
import { membershipEnquireButton } from '../../services/mock'
import HeaderTemplate from '../header/HeaderTemplate'
import dynamic from 'next/dynamic'

export interface NavigationProps {
  blueLogo: string
  whiteLogo: string
  links: Link[]
  isBackground?: boolean
  headerProps: HeaderProps
  startPostion?: 'fixed' | 'relative'
  joinButton?: {
    label?: string
    href?: string
  }
  ownEnquire?: OwnerEnquireFormProps
  hidden?: boolean
  ref?: any
}

const OwnerEnquireForm = dynamic(() => import('../forms/OwnerEnquireForm'), {
  ssr: false
})

export const Navigation = (props: {
  startPostion?: 'fixed' | 'relative'
  siteName?: string
  metaType?: string
  gymId?: string
  gymTitle?: string
  joinUrl?: string
  popup_form?: boolean
  enquireUrl?: string
  enquireForm?: string
  template?: LAYOUT_TEMPLATE
}) => {
  const {
    startPostion,
    siteName,
    metaType,
    gymId,
    gymTitle,
    joinUrl,
    popup_form,
    enquireUrl,
    enquireForm,
    template
  } = props

  const [menuPostion, setMenuPostion] = useState<
    'fixed' | 'relative' | undefined
  >(startPostion)
  const ref = useRef<any>()
  const headerRef = useRef<any>()

  const [mobileOpened, setMobileOpened] = useState(false)
  const [suburbStep, setSuburbStep] = useState(true)

  const handleSuburbStep = () => {
    setSuburbStep(!suburbStep)
  }

  const mobileMenuClick = () => {
    setMobileOpened(!mobileOpened)
  }

  const handleScroll = () => {
    let height = 100 //default value

    if (headerRef && headerRef.current) {
      height = headerRef.current.clientHeight
    }
    if (window.scrollY > height) {
      setMenuPostion('fixed')
    } else {
      setMenuPostion(startPostion)
    }
  }
  const JoinNowClick = () => {
    ReactGA.event({
      category: 'Forms',
      action: 'Join now click',
      nonInteraction: true
    })
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'join_now_click'
    })
  }

  useEffect(() => {
    window.removeEventListener('scroll', handleScroll)
    window.addEventListener('scroll', handleScroll)
  })

  useEffect(() => {
    setMenuPostion(startPostion)
  }, [startPostion])

  const membershipEnquire = {
    ...membershipEnquireButton,
    href: enquireUrl ? enquireUrl : membershipEnquireButton.href
  }

  return (
    <>
      <MenuContext.Consumer>
        {({ navigationData }) => {
          if (navigationData) {
            const {
              blueLogo,
              whiteLogo,
              links,
              headerProps,
              joinButton,
              ownEnquire,
              hidden
            } = navigationData

            const HeaderComponent = (
              templateType?: string,
              isMobile?: boolean
            ) => {
              let HeaderComp = HeaderTemplate(templateType)
              return (
                <>
                  {isMobile !== undefined ? (
                    <HeaderComp {...headerProps} isMobile={isMobile} />
                  ) : (
                    <HeaderComp {...headerProps} />
                  )}
                </>
              )
            }

            return (
              !hidden && (
                <>
                  <Head>
                    <link rel='preload' href={whiteLogo} as='image' />
                    <link rel='preload' href={blueLogo} as='image' />
                  </Head>

                  <S.NavigationWrapper navState={menuPostion} ref={ref}>
                    <S.HeaderWrapper
                      showHeader={menuPostion !== 'fixed'}
                      ref={headerRef}
                    >
                      {HeaderComponent(template)}
                    </S.HeaderWrapper>
                    <S.Container>
                      {siteName &&
                        metaType !== 'gyms.GymOpportunity' &&
                        metaType !== 'gyms.Gym' && (
                          <h1 className='hidden'>{siteName}</h1>
                        )}
                      <a href='/'>
                        <S.Logo
                          src={menuPostion ? whiteLogo : blueLogo}
                          alt={siteName}
                          menuPosition={menuPostion}
                          width='90'
                          height='90'
                        />
                        {!mobileOpened && (
                          <S.MobileLogo
                            src={whiteLogo}
                            alt={siteName}
                            width='65'
                            height='65'
                          />
                        )}{' '}
                      </a>
                      <S.DesktopMenus>
                        {links &&
                          links.map((l, i) => (
                            <S.Menu key={i} href={l.url}>
                              {l.label}
                            </S.Menu>
                          ))}
                        {!ownEnquire && (
                          <S.EnquireButton
                            {...membershipEnquire}
                            href={
                              gymId && gymTitle
                                ? `${membershipEnquire.href}?gymId=${gymId}&gymTitle=${gymTitle}&goto=${enquireForm}`
                                : `${membershipEnquire.href}?goto=${enquireForm}`
                            }
                          >
                            {membershipEnquire.label}
                          </S.EnquireButton>
                        )}
                        {ownEnquire ? (
                          <Modal buttonText='Enquire Now' title='Enquire Form'>
                            <OwnerEnquireForm {...ownEnquire} />
                          </Modal>
                        ) : joinUrl && !popup_form ? (
                          <S.JoinButton
                            href={getJoinUrlParams(joinUrl, gymTitle)}
                            onClick={JoinNowClick}
                          >
                            {joinButton?.label}
                          </S.JoinButton>
                        ) : (
                          <Modal
                            showCloseIcon={!!suburbStep}
                            buttonText={joinButton?.label}
                            cancelText={!suburbStep ? 'Cancel' : ''}
                            navState={menuPostion}
                          >
                            <GymSearchCard
                              suburbStep={suburbStep}
                              handleSuburbStep={handleSuburbStep}
                              gym={gymId}
                            />
                          </Modal>
                        )}
                      </S.DesktopMenus>

                      {/* Menu for mobile display, only on mobile */}
                      {!mobileOpened && (
                        <S.MobileMenuButton
                          color='orange'
                          onClick={mobileMenuClick}
                        >
                          <SVGIcon name={IconEnums.burger} preload />
                        </S.MobileMenuButton>
                      )}
                      <S.MobileWrapper
                        className={`${!mobileOpened && 'hidden'}`}
                        {...{ isShow: mobileOpened }}
                      >
                        <S.HeaderGroup>
                          <S.Container>
                            <S.MobileLogo
                              src={whiteLogo}
                              alt={siteName}
                              width='100%'
                            />
                            <S.MobileMenuButton onClick={mobileMenuClick}>
                              <SVGIcon name={IconEnums.close} />
                            </S.MobileMenuButton>
                          </S.Container>
                        </S.HeaderGroup>
                        <S.MobileMenusWrapper className='bg-blue bg-opacity-75'>
                          <S.Container>
                            <S.MobileMenus>
                              <S.MobileLinks>
                                {links &&
                                  links.map((l, i) => (
                                    <S.MobileLink key={i} href={l.url}>
                                      {l.label}
                                    </S.MobileLink>
                                  ))}
                              </S.MobileLinks>
                              <S.MobileButtonWrapper>
                                {!ownEnquire && (
                                  <S.EnquireButton
                                    {...membershipEnquire}
                                    href={
                                      gymId && gymTitle
                                        ? `${membershipEnquire.href}?gymId=${gymId}&gymTitle=${gymTitle}&goto=${enquireForm}`
                                        : `${membershipEnquire.href}?goto=${enquireForm}`
                                    }
                                    className='mr-2'
                                  >
                                    {membershipEnquire.label}
                                  </S.EnquireButton>
                                )}
                                {ownEnquire ? (
                                  <Modal
                                    buttonText='Enquire Now'
                                    title='Enquire Form'
                                  >
                                    <OwnerEnquireForm {...ownEnquire} />
                                  </Modal>
                                ) : metaType === 'gyms.Gym' &&
                                  joinUrl &&
                                  !popup_form ? (
                                  <S.JoinButton
                                    href={getJoinUrlParams(joinUrl, gymTitle)}
                                    onClick={JoinNowClick}
                                  >
                                    {joinButton?.label}
                                  </S.JoinButton>
                                ) : (
                                  <Modal
                                    showCloseIcon={!!suburbStep}
                                    buttonText={joinButton?.label}
                                    cancelText={!suburbStep ? 'Cancel' : ''}
                                    navState={menuPostion}
                                  >
                                    <GymSearchCard
                                      suburbStep={suburbStep}
                                      handleSuburbStep={handleSuburbStep}
                                    />
                                  </Modal>
                                )}
                              </S.MobileButtonWrapper>
                            </S.MobileMenus>
                          </S.Container>
                        </S.MobileMenusWrapper>
                        <S.MobileActions>
                          {HeaderComponent(template)}
                        </S.MobileActions>
                      </S.MobileWrapper>
                    </S.Container>
                    {!mobileOpened && metaType != 'gyms.Gym' && (
                      <S.MobileHeader>
                        {HeaderComponent(template, !mobileOpened)}
                      </S.MobileHeader>
                    )}
                  </S.NavigationWrapper>
                </>
              )
            )
          } else {
            return null
          }
        }}
      </MenuContext.Consumer>
      {/* keep page height same when menu change position to fixed to prevent page flash with the page have height same as viewport */}
      {menuPostion == 'fixed' && (
        <div
          style={{
            width: '100%',
            minHeight: ref ? ref.current?.clientHeight : 0
          }}
        ></div>
      )}
    </>
  )
}

Navigation.ApiBlockToProps = (
  menuItems: MenuAPIProps,
  siteSettings: SiteSettingProps
): NavigationProps => {
  let links = new Array<Link>()

  if (menuItems) {
    links =
      menuItems.items &&
      menuItems.items[0].menu_items.map((link: MenuProps) => {
        return {
          ...link,
          label: link.link_text || link.label
        }
      })
  }

  // Get the header call to action text from site settings if available
  let headerProps = { ...navHeaderProps }
  if (!isNullOrEmpty(siteSettings.search_banner_text_left)) {
    headerProps.CTATitle = siteSettings.search_banner_text_left
  }

  let headerTitles: [string, string] = navHeaderProps.Titles

  if (!isNullOrEmpty(siteSettings.search_banner_text_right_orange)) {
    headerTitles[0] = siteSettings.search_banner_text_right_orange
  }

  if (!isNullOrEmpty(siteSettings.search_banner_text_right_white)) {
    headerTitles[1] = siteSettings.search_banner_text_right_white
  }

  headerProps.Titles = headerTitles

  return {
    links: links,
    // TODO Logic for these
    blueLogo: logo_blue,
    whiteLogo: logo_white,
    joinButton: {
      label: 'Join now'
    },
    headerProps: headerProps
  }
}

export default Navigation
